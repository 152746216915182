var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{staticClass:"col-12"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left filter-width-300",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.clearFilter}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-spacer'),_c('ExportTableJson',{staticStyle:{"padding-top":"0px !important","margin-bottom":"15px !important"},attrs:{"export-conditions":[],"export-data":_vm.contracts,"export-fields":_vm.headers,"export-source":'document-status-report'}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.contracts,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.contracts,"height":_vm.tableHeight,"search":_vm.searchTerm,"sort-by":'Contract.etddate',"dense":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","multi-sort":"","id":"documentStatusTable"},scopedSlots:_vm._u([{key:"item.Contract.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap font-weight-bold"},[_vm._v(_vm._s(item.Contract.title))])]}},{key:"item.Contract.etddate",fn:function(ref){
var item = ref.item;
return [(item.Contract.etddate != '9999-99-99')?_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etddate)))]):_vm._e()]}},{key:"item.Contract.etadate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.Contract.etadate)))])]}},{key:"item.FullSet.uploaded",fn:function(ref){
var item = ref.item;
return [(item.FullSet.uploaded != '9999-99-99')?_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.FullSet.uploaded)))]):_c('div',{staticClass:"text-no-wrap font-weight-bold red--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('message.na')))])]}},{key:"item.FullSet.duedate",fn:function(ref){
var item = ref.item;
return [(item.FullSet.duedate != '9999-99-99')?_c('div',{staticClass:"text-no-wrap",class:item.FullSet.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''},[_vm._v(_vm._s(_vm.formatDate(item.FullSet.duedate)))]):_c('div',{staticClass:"text-no-wrap font-weight-bold red--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('message.na')))])]}},{key:"item.FullSet.overdue",fn:function(ref){
var item = ref.item;
return [(item.FullSet.overdue > 0)?_c('div',{staticClass:"text-no-wrap font-weight-bold red--text text--darken-1"},[_vm._v(_vm._s(item.FullSet.overdue))]):_vm._e()]}},{key:"item.Awb.duedate",fn:function(ref){
var item = ref.item;
return [(item.Awb.duedate != '9999-99-99')?_c('div',{staticClass:"text-no-wrap",class:item.Awb.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''},[_vm._v(_vm._s(_vm.formatDate(item.Awb.duedate)))]):_c('div',{staticClass:"text-no-wrap font-weight-bold red--text text--darken-1"},[_vm._v(_vm._s(_vm.$t('message.na')))])]}},{key:"item.Awb.overdue",fn:function(ref){
var item = ref.item;
return [(item.Awb.overdue > 0)?_c('div',{staticClass:"text-no-wrap font-weight-bold red--text text--darken-1"},[_vm._v(_vm._s(item.Awb.overdue))]):_vm._e()]}},{key:"item.DocumentStatus.remarks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_vm._l((item.DocumentStatus.remarks),function(remark){return [_c('div',{staticClass:"width-300-px ml-2"},[_vm._v(_vm._s(remark.text + ' ' + '(' + _vm.formatDate(remark.date) + ')'))])]}),(item.DocumentStatus.remarks.length > 0)?_c('v-spacer'):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.DocumentStatus.noOfPreviousRemarks > 0)?_c('div',_vm._g({staticClass:"no-of-remarks"},on),[_vm._v(_vm._s(item.DocumentStatus.noOfPreviousRemarks))]):_vm._e()]}}],null,true)},[(item.DocumentStatus.noOfPreviousRemarks > 1)?_c('span',[_vm._v(_vm._s(item.DocumentStatus.noOfPreviousRemarks + ' ' + _vm.$t('message.previousRemarks')))]):_c('span',[_vm._v(_vm._s(item.DocumentStatus.noOfPreviousRemarks + ' ' + _vm.$t('message.previousRemark')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"text-center",attrs:{"icon":""},on:{"click":function($event){return _vm.openRemarksDialog(item.Contract.id, item.Contract.title, item.Contract.type)}}},on),[(item.DocumentStatus.remarks.length == 0)?_c('v-icon',{staticClass:"grey--text text--darken-1"},[_vm._v("mdi mdi-plus")]):_c('v-icon',{staticClass:"grey--text text--darken-1",attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[(item.DocumentStatus.remarks.length == 0)?_c('span',[_vm._v(_vm._s(_vm.$t('message.addRemarks')))]):_c('span',[_vm._v(_vm._s(_vm.$t('message.updateRemarks')))])])],2)]}}])}),_c('DocumentStatusRemark',{attrs:{"dialog":_vm.remarksDialog,"contract-id":_vm.selectedContractId,"contract-no":_vm.selectedContractNo,"contract-type":_vm.selectedContractType},on:{"update:dialog":function($event){_vm.remarksDialog=$event},"dialog-closed":_vm.remarksDialogClosed,"update-done":_vm.remarksUpdated}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }