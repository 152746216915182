<template>
    <v-fade-transition>
        <div class="col-12">
            <v-sheet class="dense-inputs">
                <v-row no-gutters>
                    <v-text-field
                            :label="$t('message.filterResults')"
                            :value="searchTerm"
                            autocomplete="off"
                            class="mt-2 mb-0 force-text-left filter-width-300"
                            prepend-inner-icon="mdi-filter-outline"
                            @change="searchTerm = $event"
                    />
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="clearFilter"><v-icon>refresh</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <ExportTableJson
                            :export-conditions="[]"
                            :export-data="contracts"
                            :export-fields="headers"
                            :export-source="'document-status-report'"
                            style="padding-top: 0px !important; margin-bottom: 15px !important;"
                    />
                </v-row>
            </v-sheet>
            <v-overlay
                    :value="loading.contracts"
                    absolute
                    opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                    :headers="headers"
                    :items-per-page="-1"
                    :items="contracts"
                    :height="tableHeight"
                    :search="searchTerm"
                    :sort-by="'Contract.etddate'"
                    class="mt-0 appic-table-light specification-table"
                    dense
                    disable-pagination
                    fixed-header
                    hide-default-footer
                    multi-sort
                    id="documentStatusTable"
            >
                <template v-slot:item.Contract.title="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.Contract.title }}</div>
                </template>
                <template v-slot:item.Contract.etddate="{item}">
                    <div class="text-no-wrap" v-if="item.Contract.etddate != '9999-99-99'">{{ formatDate(item.Contract.etddate) }}</div>
                </template>
                <template v-slot:item.Contract.etadate="{item}">
                    <div class="text-no-wrap">{{ formatDate(item.Contract.etadate) }}</div>
                </template>
                <template v-slot:item.FullSet.uploaded="{item}">
                    <div class="text-no-wrap" v-if="item.FullSet.uploaded != '9999-99-99'">{{ formatDate(item.FullSet.uploaded) }}</div>
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
                </template>
                <template v-slot:item.FullSet.duedate="{item}">
                    <div class="text-no-wrap" :class="item.FullSet.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''" v-if="item.FullSet.duedate != '9999-99-99'">{{ formatDate(item.FullSet.duedate) }}</div>
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
                </template>
                <template v-slot:item.FullSet.overdue="{item}">
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-if="item.FullSet.overdue > 0">{{ item.FullSet.overdue }}</div>
                </template>
                <template v-slot:item.Awb.duedate="{item}">
                    <div class="text-no-wrap" :class="item.Awb.overdue > 0 ? 'font-weight-bold red--text text--darken-1' : ''" v-if="item.Awb.duedate != '9999-99-99'">{{ formatDate(item.Awb.duedate) }}</div>
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-else>{{ $t('message.na') }}</div>
                </template>
                <template v-slot:item.Awb.overdue="{item}">
                    <div class="text-no-wrap font-weight-bold red--text text--darken-1" v-if="item.Awb.overdue > 0">{{ item.Awb.overdue }}</div>
                </template>
                <template v-slot:item.DocumentStatus.remarks="{ item }">
                    <div class="d-flex flex-row justify-space-between align-center">
                        <template v-for="remark in item.DocumentStatus.remarks">
                            <div class="width-300-px ml-2">{{ remark.text + '     ' + '(' + formatDate(remark.date) + ')' }}</div>
                        </template>
                        <v-spacer v-if="item.DocumentStatus.remarks.length > 0"/>
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <div class="no-of-remarks" v-if="item.DocumentStatus.noOfPreviousRemarks > 0" v-on="on">{{ item.DocumentStatus.noOfPreviousRemarks }}</div>
                            </template>
                            <span v-if="item.DocumentStatus.noOfPreviousRemarks > 1">{{ item.DocumentStatus.noOfPreviousRemarks + ' ' + $t('message.previousRemarks') }}</span>
                            <span v-else>{{ item.DocumentStatus.noOfPreviousRemarks + ' ' + $t('message.previousRemark') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="openRemarksDialog(item.Contract.id, item.Contract.title, item.Contract.type)" icon class="text-center" v-on="on">
                                    <v-icon v-if="item.DocumentStatus.remarks.length == 0" class="grey--text text--darken-1">mdi mdi-plus</v-icon>
                                    <v-icon v-else small class="grey--text text--darken-1">edit</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="item.DocumentStatus.remarks.length == 0">{{ $t('message.addRemarks') }}</span>
                            <span v-else>{{ $t('message.updateRemarks') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </v-data-table>
            <DocumentStatusRemark
                    :dialog.sync="remarksDialog"
                    :contract-id="selectedContractId"
                    :contract-no="selectedContractNo"
                    :contract-type="selectedContractType"
                    @dialog-closed="remarksDialogClosed"
                    @update-done="remarksUpdated"
            />
        </div>
    </v-fade-transition>
</template>

<script>

import {formatDate, formatDateShort, numberFormat} from "Helpers/helpers";
import {api} from "Api";
const DocumentStatusRemark = () => import("Components/Appic/DocumentStatusRemark.vue");
const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "DocumentStatusList",
    components: {DocumentStatusRemark, ExportTableJson },
    data() {
        return {
            contracts: [],
            loading: {
                clear: false,
                contracts: false,
                filterResults: false
            },
            remarksDialog: false,
            searchTerm: null,
            selectedContractNo: null,
            selectedContractId: null,
            selectedContractType: null,
            tableHeight: '500'
        }
    },
    computed: {
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.contract'),
                    value: 'Contract.title',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.type'),
                    value: 'Contract.type',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.buyer'),
                    value: 'Customer.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.supplier'),
                    value: 'Supplier.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4, //temp
                    text: this.$t('message.origin'),
                    value: 'Origin.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4, //temp
                    text: this.$t('message.destination'),
                    value: 'Destination.name',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.invoice'),
                    value: 'Invoice.no',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.terms'),
                    value: 'Contract.type_insurance',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.etd'),
                    value: 'Contract.etddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.eta'),
                    value: 'Contract.etadate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.fullSetUploaded'),
                    value: 'FullSet.uploaded',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: this.$t('message.due'),
                    value: 'FullSet.duedate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.overdue'),
                    value: 'FullSet.overdue',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.awb'),
                    value: 'Awb.duedate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.overdue'),
                    value: 'Awb.overdue',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.remarks'),
                    value: 'DocumentStatus.remarks',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                }
            ]
        }
    },
    methods: {
        clearFilter() {
            this.searchTerm = null
            this.loadReport()
        },
        formatDate,
        formatDateShort,
        formatThisNumber(value, format) {
            return numberFormat(value, format)
        },
        filterResults () {
            this.loading.filterResults = true
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (200);
        },
        loadReport() {
            return new Promise((resolve, reject) => {
                this.loading.contracts = true
                api
                    .get('/reports/document-status')
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.contracts = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.contracts = false
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.contracts = false
                    });
            })
        },
        numberFormat,
        openRemarksDialog(contractId, contractNo, contractType) {
            this.remarksDialog = true
            this.selectedContractNo = contractNo
            this.selectedContractId = contractId
            this.selectedContractType = contractType
        },
        overDue (date) {
            const now = new Date()
            const testDate = new Date(date)
            if(now.getTime() > testDate.getTime()) return true
            return false
        },
        remarksDialogClosed() {
            this.remarksDialog = false;
        },
        remarksUpdated() {
            this.loadReport()
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.loadReport()
    },
    mounted() {
        this.handleResize()
        this.$emit('mounted')
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-300 {
    width: 300px !important;
    max-width: 300px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.width-200-px {
    width: 200px !important;
    max-width: 200px !important;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
</style>